export const highlightDetail = [
  {
    id: 1,
    defalut: require('@/assets/img/颊彩素材/产品模特图/SCGG1.jpg'),
    active: require('@/assets/img/颊彩素材/产品模特图/SCGG2.jpg'),
    series: '颊彩',
    seriesName: '丝绸高光',
    zhName: '酵色「丝绸高光」',
    enName: 'Silky Silk Highlighter',
    price: '69',
    of: '3.6-4.5g',
    intro: '自然水光感 为亚洲暖黄和冷白皮定制',
    imgUrl: [
      { index: 1, url: require('@/assets/img/详情/丝绸素材/丝绸高光/4.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/丝绸高光/04.jpg') },
      { index: 2, url: require('@/assets/img/详情/丝绸素材/丝绸高光/2.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/丝绸高光/02.jpg') },
      { index: 3, url: require('@/assets/img/详情/丝绸素材/丝绸高光/3.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/丝绸高光/03.jpg') },
      { index: 3, url: require('@/assets/img/详情/丝绸素材/丝绸高光/1.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/丝绸高光/01.jpg') },
    ],
    color: [
      {
        active: '#e5e0da',
        name: '#06 月落银湖',
        span1: '细密珍珠白',
        span2: '如湖面洒落的粼粼银光',
        color: require('@/assets/img/详情/丝绸素材/丝绸高光/06.jpg')
      },
      {
        active: '#d7c3c3',
        name: '#07 香槟粉梦',
        span1: '月光香槟金',
        span2: '斟满少女的午后美梦',
        color: require('@/assets/img/详情/丝绸素材/丝绸高光/07.jpg')
      }
    ]
  },
  {
    id: 2,
    defalut: require('@/assets/img/颊彩素材/产品模特图/BKGG1.jpg'),
    active: require('@/assets/img/颊彩素材/产品模特图/BKGG2.jpg'),
    series: '颊彩',
    seriesName: '贝壳高光',
    intro: '轻盈细腻 高级光泽',
    zhName: '酵色「贝壳高光」',
    enName: 'Spiral Shell Highlighter',
    price: '69',
    of: '3.6 - 4.5g',
    imgUrl: [
      { index: 1, url: require('@/assets/img/详情/贝壳素材/贝壳高光/x1.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳高光/d1.jpg') },
      { index: 2, url: require('@/assets/img/详情/贝壳素材/贝壳高光/x2.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳高光/d2.jpg') },
      { index: 3, url: require('@/assets/img/详情/贝壳素材/贝壳高光/x3.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳高光/d3.jpg') },
      { index: 3, url: require('@/assets/img/详情/贝壳素材/贝壳高光/x4.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳高光/d4.jpg') },
    ],
    color: [
      {
        active: '#dddddd',
        name: '#01 月光碎石',
        span1: '银色大闪',
        span2: '聚焦闪耀白月光',
        color: require('@/assets/img/详情/贝壳素材/贝壳高光/01.jpg')
      },
      {
        active: '#cbb2cd',
        name: '#02 耀眼薄雾',
        span1: '粉紫大闪偏光',
        span2: '甜系妆容必备',
        color: require('@/assets/img/详情/贝壳素材/贝壳高光/02.jpg')
      },
      {
        active: '#deb599',
        name: '#03 日光之吻',
        span1: '自然香槟金细闪',
        span2: '湿漉漉的水光感',
        color: require('@/assets/img/详情/贝壳素材/贝壳高光/03.jpg')
      },
      {
        active: '#f3eed5',
        name: '#05 寻迹流星',
        span1: '蓝粉香槟烤粉',
        span2: '清透妆效',
        color: require('@/assets/img/详情/贝壳素材/贝壳高光/05.jpg')
      }
    ]
  },
]